import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/chatp/Projects/farming-season/doc/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tokenomic"
    }}>{`Tokenomic`}</h1>
    <hr></hr>
    <h2 {...{
      "id": "country-life-cash"
    }}>{`Country Life Cash`}</h2>
    <p><img alt="coin" src={require("./public/images/icon-clc.png")} /></p>
    <p>{`Country Life Cash หรือ CLC ถูกสร้างขึ้นบน Polygon Chain ด้วยมาตรฐาน ERC-20`}</p>
    <ul>
      <li parentName="ul">{`สกุลเงิน ( CLC ) - `}<a parentName="li" {...{
          "href": "https://polygonscan.com/token/0x4483dd44922CC9e8012034b7d7705A6D32F1fda9"
        }}>{`0x4483dd44922CC9e8012034b7d7705A6D32F1fda9`}</a></li>
      <li parentName="ul">{`สินทรัพย์ ( NFT ) - `}<a parentName="li" {...{
          "href": "https://polygonscan.com/token/0x2c1aC4819339a051c6B30F54fb75562dDC5d6442"
        }}>{`0x2c1aC4819339a051c6B30F54fb75562dDC5d6442`}</a></li>
    </ul>
    <p><img alt="chart" src={require("./public/images/meta-chart.png")} /></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Pie`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Supply`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Percent`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Core Team`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`250M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`25%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Play & Earn`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`500M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`50%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`DEX Liquidity`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`150M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`15%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Reserved for emergency cases`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`100M`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`10%`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`อย่างที่กล่าวไป เป้าหมายของเกมนี้ไม่ใช่เกมที่เน้นเรื่องการลงทุน แต่เป็นเกมที่เน้นในด้านความสนุกและได้ความรู้เกี่ยวกับการเล่น GameFi ดังนั้นเราจึงไม่ได้ต้องการให้ราคาของ CLC พุ่งสูงจนถึงดวงจันทร์ ในทางกลับกัน เราต้องการให้ราคาของ CLC นั้นคงที่เอาไว้ เราอาจจะสัญญาไม่ได้ว่าจะควบคุมราคาได้อย่าง 100% แต่ก็จะพยายามรักษามูลค่าของ CLC ให้ราคาคงที่ให้ได้มากที่สุด`}</p>
    <p>{`เราควบคุม CLC ให้มีมูลค่าคงที่ได้ยังไง ? อันดับแรก เราจะสร้างสภาพคล่องด้วยการวางราคาเทียบระหว่าง USDC กับ CLC เมื่อมีคนเอา CLC ออกจาก Pool ของเรา ราคา CLC ก็จะเพิ่มขึ้น ในตอนนั้นเราจะ Swap CLC กลับเข้าไปใน Pool เพื่อกดราคา CLC ลงให้เท่าเดิม ซึ่งเราก็จะทำแบบนี้เมื่อมีคนเอา USDC ออกจาก Pool เช่นกัน`}</p>
    <p>{`โดยเราจะทำการควบคุมราคาและเพิ่มสภาพคล่องให้กับ Pool เป็นครั้งคราว ซึ่งมันจะช่วยให้ Pool ของเราเติบโตไปอย่างมีเสถียรภาพได้`}</p>
    <blockquote>
      <p parentName="blockquote">{`ปล. ทางเราคาดหวังให้ราคาของ CLC นั้น อยู่ที่ 30 - 3000 CLC ต่อ 1 USDC`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      